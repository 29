import React, { useMemo } from 'react'
import { localizedStrings } from 'constants/localizedStrings';
import { Row } from 'reactstrap';
import { Icon, Text } from 'components';
import {
    TextSummary,
    IconSummary,
} from './style'

export default function SummaryCard({
    total = 0,
    type,
    onClick,
}) {
    const sumarryType = useMemo(
        () => {
            const types = {
                pending: {
                    icon: "clock",
                    color: "#FFC241",
                    text: total === 1
                        ? localizedStrings.solicitationStatus.pending.singular
                        : localizedStrings.solicitationStatus.pending.plural,
                },
                approved: {
                    icon: "check",
                    color: "#1DC9B7",
                    text: total === 1
                        ? localizedStrings.solicitationStatus.approved.singular
                        : localizedStrings.solicitationStatus.approved.plural,
                },
                recused: {
                    icon: "block",
                    color: "#FF0000",
                    text: total === 1
                        ? localizedStrings.solicitationStatus.recused.singular
                        : localizedStrings.solicitationStatus.recused.plural,
                },
            }

            return types[type];
        },
        // eslint-disable-next-line
        [type]
    )

    return (
        <Row onClick={onClick} >
            <IconSummary  >
                <Icon icon={sumarryType?.icon} width="30px" height="30px" color={sumarryType?.color}
                    divProps={{
                        display: "flex",
                        justifycontent: "center",
                        padding: "4px 8px",
                    }}
                />
            </IconSummary>
            <TextSummary>
                <Text
                    color="#868E96"
                    fontSize="16px"
                    lineHeight="20px"
                    fontWeight="500"
                >
                    {total + " " + sumarryType.text}
                </Text>
            </TextSummary>
        </Row>
    )
}
