import React from 'react';
import {
    ButtonWithIconStyle
} from './style';
import { Icon, Text } from 'components'
export default function ButtonWithIcon({
    title,
    onClick,
    formId = "",
    icon,
    customTextColor = "#fff",
    customIconColor = "#fff",
    customBackgroundColor = "#192379",
    disabled = false,
    iconPosition = "right",
    ...option
}) {
    return (
        <ButtonWithIconStyle
            onClick={onClick}
            backgroundColor={disabled ? "#8a8b96" : customBackgroundColor}            
            form={formId}
            width="auto"
            disabled={disabled}
            {...option}
        >
            {
                iconPosition === 'left' &&
                <Icon
                    icon={icon}
                    width={"14px"}
                    height={"14px"}
                    color={customIconColor}
                    {...option.iconOptions}
                />
            }
            {
                title &&
                <Text
                    cursor={"pointer"}
                    fontWeight={"500"}
                    fontSize={"13px"}
                    lineHeight={"15px"}
                    color={customTextColor}
                    marginLeft={iconPosition === 'left' && "10px"}
                    marginRight={iconPosition === 'right' && "10px"}
                    {...option.textOptions}
                >
                    {title}
                </Text>
            }
            {
                iconPosition === 'right' &&
                <Icon
                    icon={icon}
                    width={"14px"}
                    height={"14px"}
                    color={customIconColor}
                    {...option.iconOptions}
                />
            }
        </ButtonWithIconStyle>
    );
}
