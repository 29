import React from 'react'
import {
    SuccessSolicitationWrapper,
    SuccessSolicitationContent
} from './style';
import { Icon, Text, Link } from 'components';
import { localizedStrings } from 'constants/localizedStrings';
import { SOLICITATION_MANAGE_PATH } from 'constants/paths';

export default function ScreenSuccessSolicitation({
    currentStep
}) {

    return (
        <SuccessSolicitationWrapper isCurrentStep={+currentStep === 3}>
            <Icon icon="check" widtg="100px" height="100px" color="#1DC9B7" />
            <SuccessSolicitationContent>
                <Text
                    color="#262D60"
                    fontWeight="500"
                    fontSize="24px"
                    lineHeight="24px"
                    marginBottom="8px"
                >
                    {localizedStrings.yourSoliciationWasSentSuccessfully}
                </Text>
                <Text
                    fontStyle="normal"
                    fontWeight="normal"
                    fontSize="13px"
                    lineHeight="13px"
                    letterSpacing="0.1px"
                    color="#868E96"
                    marginTop="8px"
                >
                    {localizedStrings.returnToSolicitationPanelToSeeYourSolicitation}
                </Text>
            </SuccessSolicitationContent>
            <Link
                href={SOLICITATION_MANAGE_PATH}
                target={"_self"}
                background="#1A237A"
                borderRadius="4px"
                padding="8px 10px"
                color="white"
                hover={{
                    color: "white",
                    textDecoration: "none",
                }}
            >
                {localizedStrings.returnToPanel}
            </Link>
        </SuccessSolicitationWrapper>
    )
}
