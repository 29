import React from 'react'
import {
    BackgroundWithIcon
} from './style';

export default function SolicitationCreateBackground() {

    return (
        <BackgroundWithIcon />
    )
}
