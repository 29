import React, { useState } from 'react'
import { BottomPagination, VirtualizedTable } from 'containers';
import { localizedStrings } from 'constants/localizedStrings';
import { useSelector } from 'react-redux';

export default function Approved({
    setPage,
    page
}) {

    const {
        approved,
        loadLoading,
        total,
    } = useSelector(state => state.solicitations);

    const [tableColumns,] = useState([
        { key: "descr", label: localizedStrings.solicitationDescription, type: "text" },
        { key: "vehicle_name", label: localizedStrings.vehicleAssociated, type: "text" },
        { key: "driver_name", label: localizedStrings.driverAssociated, type: "text" },
        { key: "period", label: localizedStrings.usagePeriod, type: "text" },
    ]);

    return (
        <>
            <VirtualizedTable
                columns={tableColumns}
                data={approved}
                context={"approved"}
                loading={loadLoading}
            />
            {!loadLoading && approved?.length !== 0 && (
                <BottomPagination
                    list={approved}
                    page={page}
                    setPage={setPage}
                    perPage={50}
                    total={total}
                />
            )}
        </>
    )
}
