export const CRISP_APP_ID = "ded1665a-6185-41dc-8ed6-634ddac0214f";

export const PRD_HOSTS = {
    "cgv-prd-web-solicitation-frontend.contele.io": true,
    "solicitacao.contelerastreador.com.br": true,
};

const host = window.location.host;

export const IS_PRD = PRD_HOSTS[host] === true

export const SYSTEM_URL = IS_PRD ? "https://app.contelerastreador.com.br/" : "https://cgv-stg-web-frontend.contele.io/"
export const API_URL = IS_PRD ? "https://cgv-prd-api.contele.io/" : "https://tmp-cgv-stg-api.contele.io/";

export const HTTP_STATUS = {
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    SUCCESS: 200,
    NOT_FOUND: 404,
};

export const MAX_LENGHT = 100000;
export const PER_PAGE_LENGTHS = ["50", "100", "500"]