import React, { useState } from "react";
import "react-virtualized/styles.css";
import { Table, Column, AutoSizer } from "react-virtualized";
import { Text, Icon } from "../../components";
import { localizedStrings } from "../../constants/localizedStrings";
import { ButtonRow, ColumnHeaderWrapper } from "./style.js";
import { format, parseISO } from "date-fns";
import { useSelector } from "react-redux";
import { convertUserMaskToDateFns } from "utils/convert";
import { getUrlParam, setUrlParam } from "utils/params";
import { EmptyStateContainer } from "containers";
import { PlaceholderAnimation } from "components/card/style";

export default function VirtualizedTable({
  data = [],
  columns,
  onRowClick,
  styleRow,
  tableHeight = 403,
  headerHeight = 30,
  rowHeight = 50,
  loading = false,
  context = "",
}) {
  const {
    user: { user_settings }
  } = useSelector(state => state.auth)
  const [listSortConfig, setListSortConfig] = useState({
    sortBy: getUrlParam('sortBy') || "",
    sortDirection: getUrlParam('sortDirection') || "DESC"
  });

  const onSort = ({ sortBy, sortDirection }) => {
    setUrlParam('sortBy', sortBy);
    setUrlParam('sortDirection', sortDirection);
    if (!Array.isArray(data) || data.length === 0) return;
    let isSortable = false;
    console.log(data, sortBy, sortDirection);
    data.sort((itemA, itemB) => {
      let sortCondition =
        typeof itemA[sortBy] === 'string' &&
        typeof itemB[sortBy] === 'string' &&
        itemA[sortBy]
          .toString()
          .toLowerCase()
          .localeCompare(itemB[sortBy].toString().toLowerCase());
      isSortable = !!itemA[sortBy];
      if (typeof itemA[sortBy] === 'number') {
        sortCondition = itemA[sortBy] - itemB[sortBy];
      }

      return sortCondition;
    });

    if (isSortable && sortDirection === "DESC") data.reverse()

    setListSortConfig({ sortBy, sortDirection });
  };

  const InformationCell = ({ cellData = "", textStyle = {}, ...props }) => (
    <Text {...textStyle} title={cellData}> {cellData} </Text>
  );
  const ErrorCell = ({ cellData = "", ...props }) => (
    <Icon
      icon={"warning"}
      width={"16px"}
      height={"16px"}
      tooltipText={props.fallbackText || localizedStrings.problemsWhenLoadingDate}
      color={"#1D1B84"}
      {...props}
    />
  );

  const cellTypes = {
    text: ({ cellData, fallbackText = "", rowData, ...props }) => {
      const arrayInformation = cellData && (
        <ul id={`id-${Math.random() * 1000}`} key={Math.random() * 1000}>
          {cellData.split(',').map((elem, index) => (<li key={index}>{elem}</li>))}
        </ul>
      );
      return (
        <Text margin-top="32px" height={rowHeight} tooltipOptions={arrayInformation && { placement: "top" }} tooltipText={arrayInformation} white-space="nowrap" overflow="hidden" textOverflow="ellipsis">
          {" "}
          {cellData || fallbackText}{" "}
        </Text>

      )
    },
    boolean: props => {
      props.cellData = props.cellData ? props.onTrue : props.onFalse;
      return InformationCell(props);
    },
    date: props => {
      try {

        const {
          short_date_format: dateFormat
        } = user_settings;

        const dateMaskFromConfiguration = convertUserMaskToDateFns({ mask: dateFormat });

        props.cellData = format(
          parseISO(
            props && props.cellData,
            props.dateFormat || "yyyy-MM-dd T hh:mm:ss",
            new Date()
          ),
          dateMaskFromConfiguration
        );
      } catch (error) {
        return ErrorCell(props);
      }
      return InformationCell(props);
    },
    datetime: props => {
      try {

        const {
          short_date_format: dateFormat,
          short_time_format: timeFormat
        } = user_settings;

        const dateMaskFromConfiguration = convertUserMaskToDateFns({ mask: `${dateFormat}`, timeMask: `${timeFormat}` });

        props.cellData = format(
          parseISO(
            props && props.cellData,
            props.dateFormat || "yyyy-MM-dd T hh:mm:ss",
            new Date()
          ),
          dateMaskFromConfiguration
        );
      } catch (error) {
        // props.cellData = false
        // console.error("error", props.cellData, error);
        return ErrorCell(props);
      }
      return InformationCell(props);
    },
    buttons: ({ rowData: item = {}, buttons = [], ...props }) => (
      <ButtonRow>
        {Array.isArray(buttons) &&
          buttons.map(button => {
            const style = typeof button.style === 'function'
              ? button.style(item)
              : button?.style || {};
            return (
              <button
                onClick={event => {
                  button.onClick && button.onClick(item, event);
                  event.preventDefault();
                  event.stopPropagation();
                  return false;
                }}
              >
                {
                  button.hasCustomElement
                    ? button.customElement && button.customElement(item)
                    : <Icon
                      {...button?.iconProps?.(item, button)}
                      icon={button.name}
                      width={button.width || "20px"}
                      height={button.height || "15px"}
                      color={button.color || "#1A237A"}
                      cursor="pointer"
                      className={button.className || ""}
                      style={{ marginRight: "5px", ...style }}
                    />
                }
              </button>
            );
          })}
      </ButtonRow>
    )
  };
  const sortIconIndicator = type => {
    return (
      <Icon
        icon={type || "ASC"}
        width={"15px"}
        height={"6px"}
        color={"#1A237A"}
        cursor="pointer"
      />
    );
  };
  const ColumnHeader = props => {
    // only first letter to upper case
    const columnTitle = (([first, ...rest]) =>
      [first?.toUpperCase(), ...rest].join(""))(props?.label || "");
    return (
      <ColumnHeaderWrapper className="column-header-wrapper" {...props.divStyle}>
        <Text
          cursor={"pointer"}
          fontWeight={"bold"}
          fontSize={"13px"}
          lineHeight={"19px"}
          {...props.textStyle}
          flex={"1"}
        >
          {columnTitle}
        </Text>
        <div>
          {listSortConfig.sortBy === props.dataKey && props.dataKey !== null ? (
            sortIconIndicator(listSortConfig.sortDirection)
          ) : (
              <>
                <Icon
                  icon={"ASC"}
                  width={"15px"}
                  height={"6px"}
                  color={"#1A237A"}
                  cursor="pointer"
                />
                <Icon
                  icon={"DESC"}
                  width={"15px"}
                  height={"6px"}
                  color={"#1A237A"}
                  cursor="pointer"
                />
              </>
            )}
        </div>
      </ColumnHeaderWrapper>
    );
  };
  const cellComponent = ({ type, ...props }) => cellTypes[type](props);

  if (loading) return <PlaceholderAnimation />;

  return (
    data?.length
      ? <AutoSizer style={{ width: "100%", height: "auto" }} disableHeight>
        {({ width }) => {
          return (
            <Table
              sort={({ sortBy, sortDirection, ...props }) =>
                onSort({ sortBy, sortDirection })
              }
              onRowClick={({ event, index, rowData }) => {
                onRowClick && onRowClick(rowData);
              }}
              overscanRowCount={10}
              {...listSortConfig}
              width={width}
              height={tableHeight}
              style={{
                maxHeight: data.length * rowHeight + headerHeight + 30
              }}
              headerHeight={headerHeight}
              rowHeight={rowHeight}
              rowCount={data.length}
              rowStyle={styleRow}
              // eslint-disable-next-line 
              key={({ dataKey }) => dataKey}
              rowGetter={({ index }) => data[index]}
            >
              {columns.map(
                ({ key = null, label = "", ...columnProps }, index) => {
                  return (
                    <Column
                      key={index}
                      dataKey={key}
                      label={label}
                      style={{ paddingLeft: '16px', paddingRight: '16px' }}
                      width={columnProps.width || width / columns.length}
                      headerRenderer={props =>
                        ColumnHeader({ ...props, ...columnProps })
                      }
                      cellRenderer={props =>
                        cellComponent({ ...props, ...columnProps })
                      }
                    />
                  );
                }
              )}
            </Table>
          );
        }}
      </AutoSizer>
      : <EmptyStateContainer context={context} />
  );
}