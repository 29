import { SYSTEM_URL } from "./environment"

export const MANAGE = "/gerenciar"
export const CREATE = "/criar"

export const SOLICITATION = "/solicitacoes"
export const LOGIN = "/login"
export const FORGOT = "/esqueci-a-senha"

//  ------ LOGIN ------  //
export const LOGIN_PATH = LOGIN
export const SYSTEM_LOGIN_FORGOT_PATH = SYSTEM_URL + LOGIN + FORGOT

//  ------ SOLICITATION ------  //
export const SOLICITATION_PATH = SOLICITATION
export const SOLICITATION_MANAGE_PATH = SOLICITATION_PATH + MANAGE
export const SOLICITATION_CREATE_PATH = SOLICITATION_PATH + CREATE