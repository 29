import React, { useMemo } from 'react'
import {
	StepStyle
} from '../style'
import { Text } from 'components'
import { localizedStrings } from 'constants/localizedStrings'
import { format, } from 'date-fns';
import { utcToZonedTime, } from 'date-fns-tz';
import { useSelector } from 'react-redux';
export default function StepThree({
	solicitation = {},
	currentStep,
}) {
	const {
		user: { user_settings }
	} = useSelector(state => state.auth)

	const timeFormmated = useMemo(
		() => {
			try {
				const {
					timezone,
				} = user_settings;

				const {
					start_date,
					end_date,
					start_time,
					end_time
				} = solicitation

				const startDateTimezoned = utcToZonedTime(start_date, timezone)
				const endDateTimezoned = utcToZonedTime(end_date, timezone)

				const startDateFormatted = format(startDateTimezoned, "dd/MM");
				const endDateFormatted = format(endDateTimezoned, "dd/MM");


				const formattedPeriod = {
					rangePeriod: `${startDateFormatted} ${localizedStrings.to} ${endDateFormatted} ${localizedStrings.from} ${start_time} ${localizedStrings.to} ${end_time}`,
					oneDay: `${startDateFormatted} ${localizedStrings.from} ${start_time} ${localizedStrings.to} ${end_time}`
				}

				if (startDateFormatted === endDateFormatted) return formattedPeriod.oneDay

				return formattedPeriod.rangePeriod

			} catch (error) {
				return ""
			}
		},
		// eslint-disable-next-line
		[
			solicitation.start_date,
			solicitation.end_date,
			solicitation.start_time,
			solicitation.end_time,
			user_settings,
		]
	)

	return (
		<StepStyle isCurrentStep={currentStep === 2}>
			<Text
				color="#5C5D66"
				fontWeight="500"
				fontSize="13px"
				margin-top="30px"
			>
				{localizedStrings.ticketDescription}
			</Text>
			<Text
				color="#212529"
				fontWeight="normal"
				fontSize="16px"
				margin-top="8px"
				whiteSpace="normal"
				overflowWrap="break-word"
				maxHeight="150px"
				overflowY="scroll"
			>
				{solicitation.descr}
			</Text>
			<Text
				color="#5C5D66"
				fontWeight="500"
				fontSize="13px"
				margin-top="30px"
				whiteSpace="normal"
			>
				{localizedStrings.periodOfUse}
			</Text>
			<Text
				color="#212529"
				fontWeight="normal"
				fontSize="16px"
				margin-top="8px"
				whiteSpace="normal"
			>
				{timeFormmated}
			</Text>
		</StepStyle>
	)
}
