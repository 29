import styled from "styled-components";

export const PageBackgroundStyle = styled.div(props => ({
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  alignItems: "center",
  background: "#F8F8FB",
  height: "inherit",
  "& >div": {
    maxWidth: "1024px",
    width: "100%",
    minHeight: "720px",
  },
  "& > div:first-child": {
    background: "#FFFFFF",
    borderRadius: "20px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.15)"
  },
  ["@media" + props.theme.device.laptopM]: {
    alignItems: "end",
    height: "unset",
    "& > div:first-child": {
        background: "#FFFFFF",
        borderRadius: "0px",
        margin: "0px",
      },
  }
}))
