import React from 'react'
import { SummaryCard } from 'components'
import {
    SummaryCardsStyles
} from './style';
import { useSelector } from 'react-redux';

export default function SummaryCards({
    onClick,
}) {

    const {
        approved,
        recused,
        pending,
        total_by_status
    } = useSelector(state => state.solicitations);

    return (
        <div>
            <SummaryCardsStyles>
                <SummaryCard
                    onClick={() => onClick({ type: "pending" })}
                    total={total_by_status?.pending || 0}
                    type="pending" />
                <SummaryCard
                    onClick={() => onClick({ type: "approved" })}
                    total={total_by_status?.approved || 0}
                    type="approved" />
                <SummaryCard
                    onClick={() => onClick({ type: "recused" })}
                    total={total_by_status?.recused || 0}
                    type="recused" />
            </SummaryCardsStyles>
        </div>
    )
}
