import styled from "styled-components";
import carsBackground from "assets/cars-background.png";

export const ContentLogin = styled.div(props => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  background: "#F8F8FB",
  height: "inherit",
  "& >div": {
    maxWidth: "1024px",
    width: "100%"
  },
  "& > div:first-child": {
    background: "#FFFFFF",
    borderRadius: "20px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.15)"
  },
  ["@media" + props.theme.device.laptopM]: {
    alignItems: "end",
    "& .ilustration-without-background": {
      display: "none",
    },
    height: "unset",
  }
}))

export const ContainerInfo = styled.div(props => ({
  background: "linear-gradient(270deg, #3B3992 0%, #1D1B84 100%)",
  display: "flex",
  height: "100%",
  borderRadius: "20px",
  "& img": {
    maxHeight: "247px",
  },
  ["@media" + props.theme.device.laptopM]: {
    display: "none"
  },
  "& > div": {
    backgroundImage: `url(${carsBackground})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column"
  }
}))

export const ContainerForm = styled.div(props => ({
  display: "flex",
  height: "100%",
  borderRadius: "20px",
  "& > div > form": {
    width: "100%",
  },
  "& > div": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "end",
    padding: "60px",
    justifyContent: "center",
  },
  ["@media" + props.theme.device.laptopM]: {
    "& > div": {
      alignItems: "center",
      display: "flex",
      padding: "20px",
    },
  },
}))

export const BottomTextContainer = styled.div(props => ({
  padding: "16px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  ...props,
}))
