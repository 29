import React, { useEffect } from 'react'
import { BottomActionButtons } from 'components/buttons'
import { localizedStrings } from 'constants/localizedStrings'
import { Text } from 'components'
import $ from 'jquery'
import {
    LogoutConfirmationStyle
} from './style';

export default function LogoutConfirmation({
    onCancel,
    onConfirm
}) {

    useEffect(() => {
        $("#logout-btn").focus()
    }, []);

    return (
        <LogoutConfirmationStyle onSubmit={(e) => {
            e.preventDefault();
            return false;
        }}>
            <Text
                color="#666666"
                fontSize="18px"
                fontWeight="500"
                lineHeight="22px"
            >
                {localizedStrings.areYouSureYouWantToLeave}
            </Text>
            <BottomActionButtons
                loading={false}
                saveText={localizedStrings.yes}
                onCancel={onCancel}
                saveButtonStyle={{
                    type: "submit",
                    id: "logout-btn"
                }}
                onSave={onConfirm}
            />
        </LogoutConfirmationStyle>
    )
}
