import {
    SOLICITATION_CREATE_SUCCESS,
    SOLICITATION_CHANGE_OPERATION_STATES,
    SOLICITATION_LOAD_SUCCESS,
    CHANGE_CURRENT_STEP,
} from './reducer';
import qs from 'qs'

import { api } from "services/api";
import { toast } from 'react-toastify';
import { localizedStrings } from 'constants/localizedStrings';
import { format } from 'date-fns';

export function changeStep(
    step
) {
    return {
        type: CHANGE_CURRENT_STEP,
        payload: {
            stepIndex: step
        }
    };
}

export function solicitationLoadSuccess({
    approved,
    recused,
    pending,
    solicitations,
    total_by_status,
    total,
}) {
    return {
        type: SOLICITATION_LOAD_SUCCESS,
        payload: {
            solicitations,
            approved,
            recused,
            pending,
            total,
            total_by_status
        }
    };
}


export function solicitationCreateSuccess(solicitation) {
    return {
        type: SOLICITATION_CREATE_SUCCESS,
        payload: {
            solicitation
        }
    };
}


export function solicitationChangeOperationStates({
    loadLoading = false,
    loadSuccess = false,
    loadFail = false,
    createLoading = false,
    createSuccess = false,
    createFail = false,
    editLoading = false,
    editSuccess = false,
    editFail = false,
    addressLoading = false,
    addressSuccess = false,
    addressFail = false,
    updateLoading = false,
    updateSuccess = false,
    updateFail = false,
}) {
    return {
        type: SOLICITATION_CHANGE_OPERATION_STATES,
        payload: {
            loadLoading,
            loadSuccess,
            loadFail,
            createLoading,
            createSuccess,
            createFail,
            editLoading,
            editSuccess,
            editFail,
            addressLoading,
            addressSuccess,
            addressFail,
            updateLoading,
            updateSuccess,
            updateFail,
        }
    };
}

export const createSolicitation = ({
    start_date,
    end_date,
    descr,
    user_id,
    organization_id,
    successStep = 3,
}) => async dispatch => {
    dispatch(solicitationChangeOperationStates({ createLoading: true }));

    try {
        const URL = "/solicitation/v1/";

        const params = {
            solicitations: [{
                descr,
                start_date,
                end_date,
            }]
        }

        const {
            // eslint-disable-next-line
            data: { solicitations }
        } = await api.post(URL, qs.stringify(params));

        toast.success(localizedStrings.success.create.solicitation);

        dispatch(solicitationCreateSuccess({
            start_date,
            end_date,
            descr,
            user_id,
            organization_id,
        }));

        dispatch(solicitationChangeOperationStates({ createSuccess: true }))
        dispatch(changeStep(successStep));
    } catch (error) {
        dispatch(solicitationChangeOperationStates({ createFail: true }));

        toast.error(localizedStrings.error.create.solicitation)
    }
};


const filterByStatus = ({
    solicitations,
}) => {
    const solicitationByTypes = {
        pending: [],
        recused: [],
        approved: [],
    }
    const types = Object.keys(solicitationByTypes);

    types.forEach(type => {
        solicitationByTypes[type] = solicitations.filter(solicitation => solicitation.status === type);
    })

    return solicitationByTypes
};
const formatSolicitationDates = ({
    solicitationsByType,
}) => {
    const solicitationByTypes = {
        pending: [],
        recused: [],
        approved: [],
    }
    const types = Object.keys(solicitationsByType);

    types.forEach(type => {
        const solicitationValuesPerType = solicitationsByType[type];

        solicitationByTypes[type] = solicitationValuesPerType.map(solicitation => {

            const startDateRaw = new Date(solicitation.start_date);
            const endDateRaw = new Date(solicitation.end_date);

            const startDate = format(startDateRaw, "dd/MM");
            const endDate = format(endDateRaw, "dd/MM");

            let startTime = format(startDateRaw, `HH'h' mm'm'`);
            let endTime = format(endDateRaw, `HH'h' mm'm'`);

            const noMinutes = " 00m";

            const hasToReplaceMinutesStartTime = !!startTime.match(noMinutes);
            const hasToReplaceMinutesEndTime = !!endTime.match(noMinutes);

            if (hasToReplaceMinutesStartTime) startTime = startTime.replace(noMinutes, "");
            if (hasToReplaceMinutesEndTime) endTime = endTime.replace(noMinutes, "");

            const formattedPeriod = {
                period: [
                    startDate, localizedStrings.to, endDate, localizedStrings.from, startTime, localizedStrings.to, endTime
                ].join(" ")
            }

            if (endDate === startDate) {
                formattedPeriod.period = [
                    startDate, localizedStrings.from, startTime, localizedStrings.to, endTime
                ].join(" ")
            }

            return {
                ...solicitation,
                ...formattedPeriod,
            }
        });
    })

    return solicitationByTypes
};

export const setVehiclesToSolicitations = data => async dispatch => {
    const solicitationWithVehiclesData = data?.solicitations?.map?.(solicitation => {
        const solicitationVehicles = data?.vehicles
            ?.filter(vehicle => solicitation.vehicles.find(v => v.id === vehicle?.id))
            ?.map(vehicle => vehicle.name)
            ?.join(", ")

        return {
            ...solicitation,
            vehicles_names: solicitationVehicles,
        }
    })

    const solicitationsByType = filterByStatus({ solicitations: solicitationWithVehiclesData });

    const {
        approved,
        recused,
        pending,
    } = formatSolicitationDates({ solicitationsByType })

    dispatch(solicitationLoadSuccess({
        approved,
        recused,
        pending,
        solicitations: solicitationWithVehiclesData,
    }));
}

const formatSolicitations = ({
    solicitations = [],
}) => {
    return solicitations.map(solicitation => ({
        ...solicitation,
        driver_name: solicitation.drivers?.map(driver => driver.name)?.join?.(", "),
        vehicle_name: solicitation.vehicles?.map(driver => driver.name)?.join?.(", "),
    }));
};

export const loadSolicitations = data => async dispatch => {
    dispatch(solicitationChangeOperationStates({ loadLoading: true }));
    try {
        const params = [];
        const filters = {
            limit: val => val && params.push("limit=" + val),
            offset: (val = 0) => params.push("offset=" + val),
            organization_id: val => val && params.push("organization_id=" + val),
            search_term: val => val && params.push("search_term=" + val),
            status: val => val && params.push("status=" + val),
            solicitation_id: val => val && params.push("solicitation_id=" + val),
        }
        Object.keys(data).forEach(filter => filters?.[filter]?.(data?.[filter]));

        const URL = "/solicitation/v1/?" + params.join("&");

        const {
            data: { solicitations, total, total_by_status }
        } = await api.get(URL);

        const formattedWithNames = formatSolicitations({ solicitations })

        const solicitationsByType = filterByStatus({ solicitations: formattedWithNames });

        const {
            approved,
            recused,
            pending,
        } = formatSolicitationDates({ solicitationsByType })

        dispatch(solicitationLoadSuccess({
            approved,
            recused,
            pending,
            solicitations,
            total_by_status,
            total
        }));
        dispatch(solicitationChangeOperationStates({ loadSuccess: true }));
    } catch (error) {
        console.log(error);
        dispatch(solicitationChangeOperationStates({ loadFail: true }));
    }
};


