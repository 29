import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
// eslint-disable-next-line 
import logger from 'redux-logger';
import { persistStore } from "redux-persist";
import persistReducers from "./persistReducer";
import rootReducer from "./modules/rootReducer";

const initialState = {};
const middlewares = [thunk];
const persistedReducer = persistReducers(rootReducer);
// middlewares.push(logger);
const store = createStore(
  persistedReducer,
  initialState,
  compose(applyMiddleware(...middlewares))
);

const persistor = persistStore(store);

export { store, persistor };
