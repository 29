import React, { useMemo, useState } from "react";
import {
  Card,
  CardContent,
  Link,
  CardTitle,
  PageBackground,
  Icon,
  Modal,
  LogoutConfirmation,
} from "components";
import Logo from "assets/logo.png";
import { localizedStrings } from "constants/localizedStrings";
import { useHistory } from "react-router-dom";
import {
  logout
} from "store/modules";
import { SOLICITATION_MANAGE_PATH, SOLICITATION_CREATE_PATH } from "constants/paths";
import {
  ImageWrapper
} from './style';
import { useDispatch } from "react-redux";

export default function ContentWithHeader({
  children,
}) {
  const history = useHistory();

  const dispatch = useDispatch();

  const [logoutModalVisible, setLogoutModalVisible] = useState(false);

  const isSolicitationPanel = useMemo(
    () => history?.location?.pathname === SOLICITATION_MANAGE_PATH,
    // eslint-disable-next-line
    [history?.location?.pathname]
  );

  return (
    <PageBackground>
      <Modal
        open={logoutModalVisible}
        setOpen={setLogoutModalVisible}
        header={
          <LogoutConfirmation
            onCancel={() => setLogoutModalVisible(isVisible => !isVisible)}
            onConfirm={() => {
              dispatch(logout());
            }}
          />
        }
      />
      <Card>
        <CardTitle
          flexDirection="row"
          justifyContent="space-between"
          padding="25px"
        >
          <ImageWrapper >
            <img src={Logo} alt="company logo" />
          </ImageWrapper>
          <div>
            <Link
              padding="8px 12px"
              border="1px solid #1D1B84"
              borderRadius="5px"
              margin="0 10px 0 10px"
              whiteSpace="pre-wrap"
              href={
                isSolicitationPanel
                  ? SOLICITATION_CREATE_PATH
                  : SOLICITATION_MANAGE_PATH}
              hover={{
                textDecoration: "none"
              }}
            >
              {
                isSolicitationPanel
                  ? localizedStrings.makeASolicitation
                  : localizedStrings.solicitationPanel
              }
            </Link>
            <Link
              padding="8px 12px"
              border="1px solid #1D1B84"
              borderRadius="5px"
              href="#"
              onClick={() => setLogoutModalVisible(isVisible => !isVisible)}
              hover={{
                textDecoration: "none"
              }}
            >
              <Icon
                icon="leave"
                width="15px"
                height="15px"
              />
            </Link>
          </div>
        </CardTitle>
        <CardContent>
          {children}
        </CardContent>
      </Card>
    </PageBackground>
  );
}
