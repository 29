import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    ContentWithHeader,
    FailStateContainer,
    SummaryCards,
    Approved,
    Pending,
    Refused,
} from "containers";

import {
    Text,
    Col,
    Link,
    Tabs,
} from "components";
import { useDispatch, useSelector } from "react-redux";
import {
    loadSolicitations,
} from "store/modules";
import "react-virtualized/styles.css";
import { localizedStrings } from "constants/localizedStrings";

import { PER_PAGE_LENGTHS } from 'constants/environment';
import { Row } from "reactstrap";

const { Tab, handleTabs } = Tabs;

export default function SolicitationManage({ history, title, subtitle, icon }) {

    const dispatch = useDispatch();

    const {
        user: {
            organization_id,
        }
    } = useSelector(state => state.auth);

    const status_map = [
        "pending",
        "approved",
        "recused",
    ];

    const loadSolicitationByStatus = (status) => {
        dispatch(
            loadSolicitations({
                limit: PER_PAGE_LENGTHS[0],
                offset: (currentPage - 1) * PER_PAGE_LENGTHS[0],
                organization_id,
                status: status_map[status],
            })
        );
    }

    const [currentPage, setPage] = useState(1);

    const [tabComponent, setTabComponent] = useState({
        tabs: [
            {
                active: true,
                name: localizedStrings.solicitationStatus.pending.capslock,
                error: false,
                componentFuncion: Pending, 
                Component: () => (
                    <Pending
                        page={currentPage}
                        setPage={setPage}
                    />
                )
            },
            {
                active: false,
                name: localizedStrings.solicitationStatus.approved.capslock,
                error: false,
                componentFuncion: Approved, 
                Component: () => (
                    <Approved
                        page={currentPage}
                        setPage={setPage}
                    />
                )
            },
            {
                active: false,
                name: localizedStrings.solicitationStatus.recused.capslock,
                error: false,
                componentFuncion: Refused,
                Component: () => (
                    <Refused
                        page={currentPage}
                        setPage={setPage}
                    />
                )
            },
        ],
        tabActive: 0
    });

    useEffect(() => {
        loadSolicitationByStatus(tabComponent.tabActive)
    }, [tabComponent.tabActive, currentPage])

    useEffect(() => {
        const modifiedTabs = tabComponent.tabs.map((tab) => {
            if(tab.active) {
                return {
                    ...tab,
                    Component: () => (
                        <tab.componentFuncion
                            page={currentPage}
                            setPage={setPage}
                        />
                    ),
                }
            }
            return tab;
        })

        setTabComponent({
            ...tabComponent,
            tabs: modifiedTabs,
        });
    }, [currentPage])

    // eslint-disable-next-line
    const onLoadFail = () => {
        return (
            <FailStateContainer
                title={localizedStrings.noClientFound}
                titleError={localizedStrings.noClientFound}
                subtitleError={
                    <Text withLink>
                        {localizedStrings.pleaseTryAgain}{" "}
                        <Link onClick={() => window.location.reload()}>
                            {localizedStrings.clickingHere}
                        </Link>
                    </Text>
                }
            />
        );
    };

    return (
        <ContentWithHeader >
            <Row style={{
                display: "inline-flex",
                padding: "16px 10px",
                alignItems: "center",
                margin: "0px",
            }}>
                <Col md="12" xl="12" >
                    <SummaryCards
                        onClick={({ type }) => {
                            const pagePerType = {
                                pending: 0,
                                approved: 1,
                                recused: 2,
                            };
                            setPage(1);
                            handleTabs(pagePerType?.[type] ?? 0, tabComponent, setTabComponent)
                        }}
                    />
                </Col>
            </Row>
            <div>
                <Tab
                    handleTabs={index => {
                        setPage(1);
                        return handleTabs(index, tabComponent, setTabComponent)
                    }}
                    tabComponent={tabComponent}
                />
            </div>
        </ContentWithHeader>
    );
}
