import React from "react";
import { CardContentStyle } from "../style";
export default function CardContent({
    children,
  ...options
}) {
  return (
    <CardContentStyle {...options}>
      {children}
    </CardContentStyle>
  );
}
