import React from "react";

import FormRowWithMultipleInputs from '../formRowWithMultipleInputs'

export default function CardInput({
  ...options
}) {

  return (
    <FormRowWithMultipleInputs
      {...options}
    />
  )
}
