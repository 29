import styled from 'styled-components';


const SummaryCardsStyles = styled.div(props => ({
    display: "flex",
    justifyContent: "flex-start",
    overflowX: "scroll",
    "&>div": {
        background: "#FFFFFF",
        border: "1px solid #CFDAE5",
        boxSizing: "border-box",
        borderRadius: "10px",
        margin: "0 30px 0 0 ",
        minWidth: "206px",
        cursor: "pointer",
    },
    "&>div:hover": {
        background: "#FAFAFF",
        transition: "all 0.2s ease-out",
    },
    ["@media" + props.theme.device.laptopM]: {
        "&>div": {
            maxWidth: "190px"
        }
    },
}));

export {
    SummaryCardsStyles
}