import styled from 'styled-components';

export const StepCircle = styled.div(props => ({
    background: props.background || "#E5E5E5",
    border: `${props.active ? "2px" : "1px"} solid ${props.active ? "#262D60" : "#868E96"}`,
    borderRadius: "20px",
    padding: "9px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    maxWidth: "40px",
    height: "40px",
    ...props,
}))
export const StepDescription = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
    ["@media" + props.theme.device.laptopM]: {
        "& p": {
            fontSize: "12px"
        },
    },
    ...props,
}))
export const StepIcon = styled.div(props => ({
    padding: "9px",
    display: "flex",
    justifyContent: "center",
    ["@media" + props.theme.device.laptopM]: {
        "& > div": {
            transform: "rotate(90deg)"
        }
    },
    ...props,
}))