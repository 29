import { localizedStrings } from "constants/localizedStrings";

export const SOLICITATION_CHANGE_OPERATION_STATES = "solicitation_change_operation_states";
export const SOLICITATION_CREATE_SUCCESS = "solicitation_create_success";
export const SOLICITATION_LOAD_SUCCESS = "solicitation_load_success";
export const CHANGE_CURRENT_STEP = 'change_current_step';

const operationStates = {
  loadLoading: false,
  loadSuccess: false,
  loadFail: false,
  createLoading: false,
  createSuccess: false,
  createFail: false,
  editLoading: false,
  editSuccess: false,
  editFail: false,
  updateLoading: false,
}

const createSteps = [
  {
    show: true,
    name: localizedStrings.steps.one.name,
    description: localizedStrings.steps.one.description,
    active: true,
    success: false,
  },
  {
    show: true,
    name: localizedStrings.steps.two.name,
    description: localizedStrings.steps.two.description,
    active: false,
    success: false,
  },
  {
    show: true,
    name: localizedStrings.steps.three.name,
    description: localizedStrings.steps.three.description,
    active: false,
    success: false,
  },
  {
    show: false,
    active: false,
    success: false,
  }
]

const INITIAL_STATE = {
  solicitations: [],
  total: 0,
  selectors: {},
  searchedAddress: {},
  steps: createSteps,
  ...operationStates,
};

export default function solicitations(state = INITIAL_STATE, action) {
  const actionTypes = {
    change_current_step() {
      return {
        ...state,
        steps: state.steps.map((step, index) => {
          if (action.payload.stepIndex !== index) return {
            ...step,
            active: false,
            success: index < action.payload.stepIndex,

          };
          return {
            ...step,
            active: true,
            success: false,
          }
        })
      }
    },
    solicitation_change_operation_states() {
      return {
        ...state,
        ...action.payload
      };
    },
    solicitation_create_success() {
      return {
        ...state,
        solicitations: [...state.solicitations, action.payload.solicitation],
      };
    },
    solicitation_load_success() {
      return {
        ...state,
        solicitations: action.payload.solicitations,
        approved: action.payload.approved,
        recused: action.payload.recused,
        pending: action.payload.pending,
        total: action.payload.total,
        total_by_status: action.payload.total_by_status,
      };
    },
  };

  if (actionTypes[action.type]) return actionTypes[action.type]();
  return state;
}
