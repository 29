import React from 'react'
import { PageBackgroundStyle } from './style'

export default function PageBackground({
    children
}) {

    return (
        <PageBackgroundStyle>
            {children}
        </PageBackgroundStyle>
    )
}
