import {
  VEHICLE_LOAD_SUCCESS,
  VEHICLE_CHANGE_OPERATION_STATES,
} from "./reducer";
import { api } from "services/api";


function fetchLoadSuccess({ vehicles, total }) {
  return {
    type: VEHICLE_LOAD_SUCCESS,
    payload: {
      vehicles,
      total,
    }
  };
}

function vehicleChangeOperationStates({
  loadLoading = false,
  loadSuccess = false,
  loadFail = false,
  createLoading = false,
  createSuccess = false,
  createFail = false,
  editLoading = false,
  editSuccess = false,
  editFail = false
}) {
  return {
    type: VEHICLE_CHANGE_OPERATION_STATES,
    payload: {
      loadLoading,
      loadSuccess,
      loadFail,
      createLoading,
      createSuccess,
      createFail,
      editLoading,
      editSuccess,
      editFail,
    }
  };
}

const loadVehicles = data => async (dispatch) => {
  dispatch(vehicleChangeOperationStates({ loadLoading: true, }));
  try {
    const params = [];
    const filters = {
      organization_id: val => val && params.push("organization_id=" + val),
      vehicle_id: val => val && params.push("vehicle_id=" + val),
      limit: val => val && params.push("limit=" + val),
      offset: (val = 0) => params.push("offset=" + val),
      search_term: val => val && params.push("search_term=" + val),
      status: val => val && params.push("status=" + val),
    }
    Object.keys(data).forEach(filter => filters?.[filter]?.(data?.[filter] ?? false))

    const URL = "/vehicle/v1?" + params.join("&");

    const {
      data: { vehicles, total }
    } = await api.get(URL);

    dispatch(fetchLoadSuccess({ vehicles, total }));
    dispatch(vehicleChangeOperationStates({ loadSuccess: true, }));
  } catch (error) {
    dispatch(vehicleChangeOperationStates({ loadFail: true }));
  }
};



export {
  loadVehicles,
  vehicleChangeOperationStates,
}