import LocalizedStrings from 'react-localization';

const localizedStrings = new LocalizedStrings({
  pt: {
    solicitationPanel: "Painel de Reservas",
    makeASolicitation: "Fazer Reserva",
    step: step => "Passo " + step,
    solicitationDescription: "Descrição da Reserva",
    reasonRefused: "Motivo",
    ticketDescription: "Descrição do Chamado",
    usagePeriod: "Período de uso",
    loading: "Carregando",
    waitUntilLoadingEnds: "Aguarde até o fim do carregamento.",
    periodOfUse: "Período de utilização.",
    vehicleAssociated: "Veículo associado",
    driverAssociated: "Motorista associado",
    reason: "Motivo",
    steps: {
      one: {
        name: "Descrição da reserva",
        description: "Descreve os motivos da sua reserva de veículo.",
        placeholder: "Ex.: Eu preciso do veículo para um transporte de 10 passageiros. Nome do veículo.",

      },
      two: {
        name: "Período de utilização",
        description: "Selecione a data e o período de utilização através dos inputs abaixo",
      },
      three: {
        name: "Confirmação e envio",
        description: "Confira todos os dados abaixo da sua reserva",
      }
    },
    success: {
      create: {
        solicitation: "reserva cadastrada com sucesso",
      },
    },
    error: {
      create: {
        solicitation: "Problemas ao criar a reserva",
      },
    },
    solicitationStatus: {
      pending: {
        plural: "pendentes",
        singular: "pendente",
        capslock: "Pendentes",
        capslockSingular: "Pendente",
      },
      approved: {
        plural: "aprovados",
        singular: "aprovado",
        capslock: "Aprovados",
        capslockSingular: "Aprovado",
      },
      recused: {
        plural: "recusados",
        singular: "recusado",
        capslock: "Recusados",
        capslockSingular: "Recusado",
      },
    },
    descriptionTip: "Se possivel, seja objetivo em sua descrição.",
    nextStep: "Próximo passo",
    noVehicleSolicitations: "Nenhuma reserva de Veículo",
    lastStep: "Passo anterior",
    to: "até",
    from: "das",
    fromPagination: 'De',
    until: 'Até',
    registersLowerCase: "registros",
    atDay: "no dia",
    typeEmail: "Digite o email",
    developedByFullText: () => "Desenvolvido pela Contele Rastreadores  -  Todos os direitos reservados " + new Date().getFullYear(),
    access: "Accessar",
    typeEmailExample: "Ex.: maria@email.com",
    typeYourEmail: "Digite o seu email",
    typePassword: "Digite a senha",
    fieldRequired: "Campo obrigatório",
    fieldFormatRequired: "Campo não está no formato correto",
    emailPasswordWrong: "Email ou senha incorretos",
    passwordError: "Problemas ao salvar nova senha",
    selectDate: "Selecione a data",
    startHour: "Hora de inicio",
    endHour: "Hora Final",
    yourSoliciationWasSentSuccessfully: "Sua reserva foi enviada com sucesso",
    returnToSolicitationPanelToSeeYourSolicitation: "Volte ao painel para acompanhar sua reserva em tempo real.",
    returnToPanel: "Voltar ao painel",
    typeStartHour: "Digite a hora de inicio",
    typeEndHour: "Digite a hora final",
    passwordWrong: "As duas senhas estão diferentes",
    emailWrongOrDoestExists: "E-mail digitado errado ou não existe",
    productDescription: "Solução inteligente para o aumento de produtividade e redução de custos da sua frota.",
    forgotMyPassoword: "Esqueci minha senha",
    cancel: "Cancelar",
    save: "Salvar",
    yes: "Sim",
    areYouSureYouWantToLeave: "Tem certeza que deseja sair?",
    formVehicleSolicitation: "Formulário de reserva de veículos",
    formVehicleSolicitationDescription: "Siga os passos abaixo e preencha o fomulário ao lado para fazer sua reserva de veículos."
  }
}
);
const [userLang] = (navigator.language || navigator.userLanguage).split("-");
export { localizedStrings, userLang };
