import React, { forwardRef, useState, useEffect } from "react";
import { DateLabel, DateDiv } from "./style.js";
import { InputLabel } from "components/card/cardInput/style"
import { Icon } from 'components';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Calendar from 'react-calendar'
import format from 'date-fns/format';
import sub from 'date-fns/sub'
import add from 'date-fns/add'
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';

import './styles.css';

function DateInput (
  {
    error,
    onChange,
    placeholder,
    name,
    label,
    id = 'input-date',
    value,
    required,
    isMulti,
    isMonth,
    style,
    type = 'calendar',
    hasDefaultValue = true,
    monthDefault = false,
    ...option
  }
) {
  const [valueInputDate, setValueInputDate] = useState([new Date(), new Date()]);
  const [valueInputDateCalendar, setValueInputDateCalendar] = useState([new Date(), new Date()]);
  const [valueCalendar, setValueCalendar] = useState([type === 'calendar' && value ? value : new Date()]);
  const [isMultiMonth, setIsMultiMonth] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [dateStart, setDateStart] = useState(format(new Date(), 'dd/MM/yyyy'));

  const [dateEnd, setDateEnd] = useState(format(new Date(), 'dd/MM/yyyy'));

  const [date, setDate] = useState(format(type === 'calendar' && value ? value : new Date(), 'dd/MM/yyyy'));

  const onChangeCalendar = (val) => {
    if (val === null) return;
    setValueCalendar(val);
    setDate(format(val, 'dd/MM/yyyy'));
    const data = { target: { value: format(val, 'yyyy-MM-dd') } };
    onChange('', data);
    setIsOpen(isOpen => isOpen = !isOpen);
  };

  const onChangeDate = (val) => {
    if (val === null) return;
    setValueInputDate(val);
    const [startDate = new Date(), endDate = new Date()] = val;
    const data = { startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd') };
    onChange('', data);
  }

  const onChangeDateCalendar = (val) => {
    if (val === null) return;
    if (isMulti) {
      setValueInputDateCalendar(val);
      isMultiMonth.length <= 1 && isMultiMonth.push(val);
      isMultiMonth.length <= 1 && setIsMultiMonth(isMultiMonth);
      if (isMultiMonth.length === 2) {
        const [startofMonthSelected, endOfMonthSelected] = isMultiMonth;
        const startOfMonthFormated = format(startOfMonth(startofMonthSelected), 'yyyy-MM-dd');
        setDateStart(format(startOfMonth(startofMonthSelected), 'dd/MM/yyyy'))
        const endOfMonthFormated = format(endOfMonth(endOfMonthSelected), 'yyyy-MM-dd');
        setDateEnd(format(endOfMonth(endOfMonthSelected), 'dd/MM/yyyy'));
        setIsOpen(isOpen => isOpen = !isOpen);
        const data = { startDate: startOfMonthFormated, endDate: endOfMonthFormated };
        onChange('', data);
        setIsMultiMonth([]);
      }
      return;
    }
    setValueInputDateCalendar(val);
    setIsOpen(isOpen => isOpen = !isOpen);
    const startOfMonthFormated = format(startOfMonth(val), 'yyyy-MM-dd');
    setDateStart(format(startOfMonth(val), 'dd/MM/yyyy'))
    const endOfMonthFormated = format(endOfMonth(val), 'yyyy-MM-dd');
    setDateEnd(format(endOfMonth(val), 'dd/MM/yyyy'));
    const data = { startDate: startOfMonthFormated, endDate: endOfMonthFormated };
    onChange('', data);
  }

  const setDefaultDate = () => {
    try {
      if(!hasDefaultValue) return;
      // eslint-disable-next-line
      if(!type) throw 'No caledar type given';

      const today = new Date();

      const oneWeekAfterToday = add(today, { weeks: 1 });

      const oneMonthBefore = sub(today, { months: 1 });

      const inputs = {
        dateRangePicker: () => {
          onChangeDate([
            today,
            monthDefault ? oneMonthBefore : oneWeekAfterToday,
          ]);
          return true;
        },
        monthRangePicker: () => {
          onChangeDateCalendar(startOfMonth(oneMonthBefore));
          onChangeDateCalendar(endOfMonth(today));
          setIsOpen(false);
          return true
        },
        calendar: () => {
          onChangeCalendar(today);
          setIsOpen(false);
          return true
        },
      };

      return inputs?.[type]?.();

    } catch (error) {
      console.log(error);
    }
    
    return false;
  }

  useEffect(() => {

    const inputs = document.querySelectorAll('.calendar-input .react-daterange-picker__inputGroup__input');

    if (inputs) inputs.forEach(elem => elem.setAttribute('disabled', true));

    setDefaultDate();
// eslint-disable-next-line
  }, [])

  const input = {
    monthRangePicker: () => (
      <div style={{ height: '70px' }}>
        <InputLabel required={required}>
          {label} <span>*</span>{" "}
        </InputLabel>
        <div style={{ display: 'flex' }}>
          <DateLabel
            error={error}
            htmlFor={id}
            onClick={() => setIsOpen(isOpen => isOpen = !isOpen)}
          >
            <DateDiv>
              <Icon id={"date-svg"} icon={"calendar"} width={'16px'} height={'16px'} color='#868E96' />
            </DateDiv>
            {`${dateStart} até ${dateEnd}`}
          </DateLabel>
          <div style={{ display: isOpen ? 'block' : 'none', marginTop: '6.5%', ...option.calendarStyle }}>
            <Calendar
              allowPartialRange={isMulti}
              selectRange={isMulti}
              className={['react-calendar-component', 'calendar-input']}
              view='year'
              returnValue={isMulti && "range"}
              onClickMonth={onChangeDateCalendar}
              value={valueInputDateCalendar}
              defaultValue={valueInputDateCalendar}
            />
          </div>
        </div>
      </div>
    ),
    dateRangePicker: () => (
      <div style={{ cursor: 'pointer', ...style }} onClick={() => setIsOpen(isOpen => isOpen = true)}>
        <InputLabel required={required}>
          {label} <span>*</span>{" "}
        </InputLabel>
        <DateRangePicker
          onCalendarClose={() => setIsOpen(isOpen => isOpen = false)}
          isOpen={isOpen}
          rangeDivider={'até'}
          clearIcon={null}
          calendarIcon={<Icon icon={'calendar'} width={'16px'} height={'16px'} color='hsl(0,0%,60%)' />}
          className={['react-calendar-input', 'calendar-input']}
          dayPlaceholder={'--'}
          monthPlaceholder={'--'}
          yearPlaceholder={'--'}
          required
          onChange={val => onChangeDate(val)}
          value={valueInputDate}
          format={"dd/MM/yyyy"}
        />
      </div>
    ),
    calendar: () => (
      <div style={{ height: '70px', ...option.divStyle }}>
        <InputLabel required={required}>
          {label} <span>*</span>{" "}
        </InputLabel>
        <div style={{ display: 'flex' }}>
          <DateLabel
            style={style}
            error={error}
            htmlFor={id}
            onClick={() => setIsOpen(isOpen => isOpen = !isOpen)}
          >
            <DateDiv>
              <Icon id={"date-svg"} icon={"calendar"} width={'16px'} height={'16px'} color='#868E96' />
            </DateDiv>
            {`${date}`}
          </DateLabel>
          <div style={{ display: isOpen ? 'block' : 'none', marginTop: '6.5%', ...option.calendarStyle }}>
            <Calendar
              className={['react-calendar-component', 'calendar-input']}
              onChange={val => onChangeCalendar(val)}
              value={valueCalendar}
              defaultValue={valueCalendar}
              {...option.calendar}
            />
          </div>
        </div>
      </div>
    )
  }

  return input[type]();
}

const forwardInput = forwardRef(DateInput);
export default forwardInput;
