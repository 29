import React, { useMemo, useState } from 'react'
import { Text, ButtonWithIcon } from 'components'
import { localizedStrings } from 'constants/localizedStrings'
import {
    StepHeaderStyle,
    StepBottomButtons,
    StepHeaderWrapper,
    StepsForm,
} from './style'
import { changeStep } from 'store/modules';
import { useDispatch } from 'react-redux';

export default function StepHeader({
    showNextButton,
    currentStep,
    children,
    onSave = () => { },
}) {
    const dispatch = useDispatch();

    const [stepConfiguration,] = useState({
        max: 3,
        min: 0,
    });

    const currentStepContent = useMemo(
        () => {
            const currentIndex = currentStep?.index;
            return currentIndex
        },
        [currentStep]
    );

    return (
        <StepHeaderWrapper>
            <StepHeaderStyle>
                {
                    (+currentStepContent) < 3 &&
                    <Text
                        color="#868E96"
                        fontWeight="500"
                        fontSize="14px"
                    >
                        {localizedStrings.step(+currentStepContent + 1) + " - " + currentStep?.name}
                    </Text>
                }
                <div>
                    <Text
                        color="#262D60"
                        fontWeight="500"
                        fontSize="24px"
                    >
                        {currentStep?.name}
                    </Text>
                    <Text
                        color="#868E96"
                        fontWeight="500"
                        fontSize="14px"
                        padding="16px 0"
                    >
                        {currentStep?.description}
                    </Text>
                    <StepsForm >
                        {children}
                        {
                            (+currentStepContent) < stepConfiguration.max &&
                            <StepBottomButtons  >
                                {
                                    (+currentStepContent) > stepConfiguration.min &&
                                    <ButtonWithIcon
                                        title={localizedStrings.lastStep}
                                        icon="arrow-left"
                                        iconPosition="left"
                                        customBackgroundColor={"transparent"}
                                        customIconColor="#868E96"
                                        customTextColor="#868E96"
                                        border={"none"}
                                        onClick={() => {
                                            dispatch(changeStep(+currentStepContent - 1))
                                        }}
                                    />
                                }
                                <ButtonWithIcon
                                    title={localizedStrings.nextStep}
                                    icon="arrow"
                                    margin="0 0 0 auto"
                                    onClick={() => {
                                        const hasReachedLimit = (currentStepContent + 1) === stepConfiguration.max;

                                        if (hasReachedLimit) return onSave();

                                        dispatch(changeStep(+currentStepContent + 1));
                                    }}
                                    disabled={!showNextButton}
                                />
                            </StepBottomButtons>
                        }
                    </StepsForm>
                </div>
            </StepHeaderStyle>
        </StepHeaderWrapper>
    )
}
