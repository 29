import React from 'react'
import { Row } from 'reactstrap'
import { Col, Text, Icon } from 'components'
import {
    StepCircle,
    StepDescription,
    StepIcon,
} from './style'

const icon = {
    success: "#fff",
};

const text = {
    success: "#868E9640",
    default: "#868E96",
    active: "#262D60",
};

const background = {
    success: "#1DC9B7",
    default: "#E5E5E5",
    active: "#E5E5E5",
};

const types = {
    icon,
    text,
    background
}
export default function StepItem({
    step, index
}) {

    const getStatus = () => {
        if (step.success) return "success";
        if (step.active) return "active";
        return "default";
    };

    const getColor = ({
        status = getStatus(),
        type = "icon",
    }) => types[type][status];

    return (
        <Row
            style={{
                padding: "20px 0",
            }}
        >
            <Col md="4" xl="4" marginBottom={"0px"}>
                <StepCircle {...step} background={getColor({ type: "background" })}>
                    {
                        step.success
                            ? <Icon
                                icon={"checkmark"}
                                color={getColor({ type: "icon" })}
                                width="12px"
                                height="12px"
                                divProps={{
                                    marginRight: "3px"
                                }}
                            />
                            : <Text
                                color={getColor({ type: "text" })}
                                fontSize="19px"
                                lineHeight="18px"
                                fontWeight="bold"
                                whiteSpace="normal"
                            >
                                {index + 1}
                            </Text>
                    }

                </StepCircle>
            </Col>
            <Col md="6" xl="6" marginBottom={"0px"}>
                <StepDescription {...step}>
                    <Text
                        color={getColor({ type: "text" })}
                        fontSize="17px"
                        lineHeight="18px"
                        fontWeight="500"
                        whiteSpace="normal"
                    >
                        {step?.name?.toUpperCase?.()}
                    </Text>
                </StepDescription>
            </Col>
            <Col md="2" xl="2" marginBottom={"0px"}>
                {
                    step?.active &&
                    <StepIcon {...step}>
                        <Icon
                            icon={"arrow"}
                            width="15px"
                            height="15px"
                            color="#262D60"
                        />
                    </StepIcon>
                }
            </Col>
        </Row>
    )
}
