import React from 'react'
import {
    TextArea,
    Text
} from 'components';
import {
    StepStyle
} from '../style'

import { localizedStrings } from 'constants/localizedStrings';

export default function StepOne({
    solicitation,
    onChange,
    currentStep,
}) {

    return (
        <StepStyle isCurrentStep={+currentStep === 0}>
            <TextArea
                onChange={onChange}
                label={localizedStrings.steps.one.name}
                name={"descr"}
                placeholder={localizedStrings.steps.one.placeholder}
                required={true}
                maxLength="200"
                defaultValue={solicitation["descr"]}
            />
            <Text
                color="#868E96"
                fontSize="15px"
                fontWeight="normal"
                fontHeight="20px"
                whiteSpace="normal"
                marginTop="5px"
            >
                {localizedStrings.descriptionTip}
            </Text>
        </StepStyle>
    )
}
