import React, { useState } from 'react'
import { BottomPagination, VirtualizedTable } from 'containers';
import { localizedStrings } from 'constants/localizedStrings';
import { useSelector } from 'react-redux';

export default function Refused({
    setPage,
    page,
}) {

    const {
        recused,
        loadLoading,
        total,
    } = useSelector(state => state.solicitations);

    const [tableColumns,] = useState([
        { key: "descr", label: localizedStrings.solicitationDescription, type: "text" },
        { key: "descr_recused", label: localizedStrings.reasonRefused, type: "text" },
        { key: "period", label: localizedStrings.usagePeriod, type: "text" },
    ]);

    return (
        <>
            <VirtualizedTable
                columns={tableColumns}
                data={recused}
                context={"recused"}
                loading={loadLoading}
            />
            {!loadLoading && recused?.length !== 0 && (
                <BottomPagination
                    list={recused}
                    page={page}
                    setPage={setPage}
                    perPage={50}
                    total={total}
                />
            )}
        </>
    )
}
