import React, { useEffect, useState } from "react";
import { Text, Col, } from "components";
import { LoginForm } from "containers";
import { login, userChangeOperationStates } from "store/modules";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Logo from "assets/logo.png";

import {
    ContentLogin,
    ContainerInfo,
    ContainerForm,
    BottomTextContainer,
} from "./style";
import { localizedStrings } from "constants/localizedStrings";
import { Row } from "reactstrap";
import IlutrationWithoutBackground from "assets/ilustration-without-background.png";

export default function DriverLogin({ history }) {

    const { register, setValue, handleSubmit, getValues } = useForm({
        defaultValues: {
            email: "",
            password: "",
        }
    });

    const dispatch = useDispatch();

    const {
        loginFail
    } = useSelector(state => state.auth);


    const [errors, setErrors] = useState({
        email: {
            error: false,
            message: localizedStrings.fieldRequired
        },
        password: {
            error: false,
            message: localizedStrings.fieldRequired
        },
        emailPasswordWrong: {
            error: false,
            message: localizedStrings.emailPasswordWrong
        }
    });
    const [fieldsToValidate,] = useState([
        "email",
        "password",
    ]);

    const showError = (field, message = localizedStrings.fieldRequired) => {
        if (!errors[field]) return;
        errors[field].message = message;
        errors[field].error = true;
    }
    const clearError = field => {
        if (!errors[field]) return;
        errors[field].message = "";
        errors[field].error = false;
    }

    function handleError({
        fields
    }) {
        let hasError = false;
        try {
            fields.forEach(field => {
                const fieldValue = getValues()?.[field] ?? "";

                const hasValue = fieldValue.toString().trim();

                hasValue
                    ? clearError(field)
                    : showError(field)
                hasError += !hasValue
            })

            setErrors({ ...errors });
        } catch (error) {
            console.log(error);
        }
        return !!hasError
    }


    useEffect(() => {
        if (loginFail) {
            errors.emailPasswordWrong.error = true;
            setErrors({ ...errors });
        }
        // eslint-disable-next-line
    }, [loginFail]);

    useEffect(() => {

        dispatch(userChangeOperationStates({}));
        // eslint-disable-next-line
    }, []);

    function handleLogin() {
        const hasError = handleError({ fields: fieldsToValidate });

        if (!hasError) dispatch(login({
            ...getValues(),
        }));
    }

    return (
        <ContentLogin>
            <div>
                <Row style={{
                    margin: "0px"
                }}>
                    <Col xl="6" xxl="6"
                        style={{
                            padding: '0px'
                        }}
                    >
                        <ContainerInfo>
                            <div>
                                <Text
                                    color="#fff"
                                    font-size="20px"
                                    fontWeight="500"
                                    white-space="normal"
                                    line-height="28px"
                                    text-align="justify"
                                    padding="20px 70px 20px"
                                >
                                    {localizedStrings.productDescription}
                                </Text>
                                <img src={IlutrationWithoutBackground} className="ilustration-without-background" alt="ilustration without background" />
                            </div>
                        </ContainerInfo>
                    </Col>
                    <Col xl="6" xxl="6">
                        <ContainerForm>
                            <div>
                                <img src={Logo} alt="company logo" />
                                <LoginForm
                                    onSubmit={handleSubmit(handleLogin)}
                                    errors={errors}
                                    inputsConfig={{
                                        onChange: setValue,
                                        register,
                                        getValues,
                                    }}
                                />
                            </div>
                        </ContainerForm>
                    </Col>
                </Row>
            </div>
            <BottomTextContainer>
                <Text
                    color="#868E96"
                    fontSize="14px"
                >
                    {localizedStrings.developedByFullText?.()}
                </Text>
            </BottomTextContainer>
        </ContentLogin>
    );
}
