
import styled from 'styled-components';

const containerWrapper = styled.div(props => ({
    height: 'inherit',
    display: 'flex',
    flexDirection: 'row',
}))
const scrollableContainer = styled.div(props => ({
    flex: "1",
    backgroundColor: "#F8F8FB",
    overflowY: "auto"
}))
const buttonRow = styled.div(props => ({
    display: "flex", flexDirection: "row",
}))

const columnHeaderWrapper = styled.div(props => ({
    display: "flex",
    flexDirection: "row",
    paddingLeft: '16px',
    paddingRight: '16px',
    position: 'relative',
    "& div": {
        padding: " 0 16px 0 0",
        right: "10px",
        justifyContent: "center",
        display: "flex", flexDirection: "column",
    },
    ...props
}))


export {
    containerWrapper as ContainerWrapper,
    scrollableContainer as ScrollableContainer,
    buttonRow as ButtonRow,
    columnHeaderWrapper as ColumnHeaderWrapper,
}
