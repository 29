import React from 'react'
import { Row } from 'reactstrap';
import { Col, StepItem } from 'components';
import { useSelector } from 'react-redux';

export default function Steps() {
    const {
        steps
    } = useSelector(state => state.solicitations)
    return (
        <Row >
            {
                steps?.map?.((step, index) => (
                    step?.show &&
                    <Col
                        xxs="4"
                        xl="12"
                        key={index}
                        style={{
                            margin: "0px",
                            padding: "0 5px 0 0"
                        }}
                    >
                        <StepItem
                            step={step}
                            index={index}
                        />
                    </Col>
                ))
            }
        </Row>
    )
}
