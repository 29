import styled from 'styled-components';
import { StepStyle } from '../stepContents/style';

export const SuccessSolicitationWrapper = styled(StepStyle)(props => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    opacity: props.isCurrentStep ? "1" : "0",
    position: props.isCurrentStep ? "relative" : "absolute",
    zIndex: props.isCurrentStep ? "999" : "0",
    width: props.isCurrentStep ? "auto" : "0",
    top: "0",
    left: "0",
}))
export const SuccessSolicitationContent = styled.div(props => ({
    margin: "50px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
}))
