import styled from 'styled-components';

export const StepHeaderStyle = styled.div(props => ({
    background: "#F8F8FB",
    borderRadius: "20px",
    width: "100%",
    height: "100%",
    padding: "30px",
    "& > div": {
        padding: "40px"
    },
    ["@media" + props.theme.device.laptopM]: {
        padding: "25px",
        borderRadius: "0",
        "& > div": {
            padding: "25px 0 0 0"
        }
    },
    ...props,
}));
export const StepHeaderWrapper = styled.div(props => ({
    borderLeft: "1px solid #D5DFE8",
    height: "100%",
    padding: "10px",
    ["@media" + props.theme.device.laptopM]: {
        padding: "0",
    },
    ...props,
}));
export const StepBottomButtons = styled.div(props => ({
    padding: "70px 0 0 0",
    display: "flex",
    justifyContent: "space-between",
    ...props,
}));

export const StepsForm = styled.div(props => ({
    ...props,
}));

