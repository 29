import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  Login,
  SolicitationCreate,
  SolicitationManage,
} from "./pages/";

import { localizedStrings } from "./constants/localizedStrings";
import {
  LOGIN_PATH,
  SOLICITATION_MANAGE_PATH,
  SOLICITATION_CREATE_PATH,
} from "constants/paths";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component, ...options }) => {
  const {
    // eslint-disable-next-line 
    user: { id, role_id, token }
  } = useSelector(state => state.auth);

  const isAuthenticated = !!id && !!token;

  if (isAuthenticated) {
    return <Route {...options} component={component} />;
  }

  return <Redirect from={options.location.pathname} to={LOGIN_PATH} />;
};

const LoginRoute = ({ component, ...options }) => {
  const {
    user: { id, token }
  } = useSelector(state => state.auth);

  const isAuthenticated = !!id && !!token;

  if (!isAuthenticated) {
    return <Route {...options} component={component} />;
  }

  return <Redirect to={SOLICITATION_MANAGE_PATH} />;
};

export default function Router() {

  return (
    <Switch>
      <PrivateRoute
        exact
        path={SOLICITATION_CREATE_PATH}
        component={props => (
          <SolicitationCreate
            {...props}
            title={localizedStrings.clients}
            description={localizedStrings.newClient}
          />
        )}
      />
      <PrivateRoute
        exact
        path={SOLICITATION_MANAGE_PATH}
        component={props => (
          <SolicitationManage
            {...props}
            title={localizedStrings.clients}
            description={localizedStrings.manage}
            icon={"clients"}
          />
        )}
      />

      <LoginRoute path={LOGIN_PATH} component={Login} />
      <Redirect from="/" to={SOLICITATION_MANAGE_PATH} />

    </Switch>
  );
}

export { Router };
