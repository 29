import React, { useState } from 'react'
import { createSolicitation } from 'store/modules';
import { useSelector, useDispatch } from 'react-redux';
// eslint-disable-next-line
import { localizedStrings } from 'constants/localizedStrings';
import { Row } from 'reactstrap';
import {
    Col,
    Text,
    SolicitationCreateBackground,
} from 'components';
import {
    ContentWithHeader,
    Steps,
    StepsContent,
} from 'containers';
import { utcToZonedTime } from 'date-fns-tz';

export default function ClientsCreate({
    history,
}) {
    const dispatch = useDispatch();

    const {
        user: {
            id: user_id,
            organization_id,
            user_settings,
        }
    } = useSelector(state => state.auth);

    const [solicitation, setSolicitation] = useState({
        descr: "",
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
        user_id,
        organization_id,
    });

    const onSave = () => {

        const {
            timezone,
        } = user_settings;

        const {
            start_date,
            end_date,
            start_time,
            end_time,
            descr,
            user_id,
            organization_id,
        } = solicitation

        const startDateTimezoned = utcToZonedTime(start_date + "T" + start_time, timezone);

        const endDateTimezoned = utcToZonedTime(end_date + "T" + end_time, timezone);

        dispatch(createSolicitation({
            start_date: startDateTimezoned,
            end_date: endDateTimezoned,
            descr,
            user_id,
            organization_id,
        }))
    }

    return (
        <ContentWithHeader >
            <Row style={{
                display: "inline-flex",
                margin: "0px",
                padding: "0px",
                height: "100%"
            }}>
                <Col md="12" xl="4" style={{
                    padding: "25px",
                    position: "relative",
                }}>
                    <Text
                        color="#262D60"
                        fontSize="20px"
                        fontWeight="500"
                        fontHeight="24px"
                        whiteSpace="normal"
                        margin="15px 0"
                        className="hide-mobile"
                    >
                        {localizedStrings.formVehicleSolicitation}
                    </Text>
                    <Text
                        color="#868E96"
                        fontSize="15px"
                        fontWeight="normal"
                        fontHeight="20px"
                        whiteSpace="normal"
                        className="hide-mobile"
                    >
                        {localizedStrings.formVehicleSolicitationDescription}
                    </Text>
                    <Steps />
                    <SolicitationCreateBackground />
                </Col>
                <Col md="12" xl="8" style={{
                    height: "100%",
                    padding: "0px"
                }}>
                    <StepsContent
                        solicitation={solicitation}
                        setSolicitation={setSolicitation}
                        onSave={onSave}
                    />
                </Col>
            </Row>

        </ContentWithHeader>
    )
}
