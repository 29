import React, { memo } from 'react';
import { Text } from '../../components'
import { EmptyStateWrapper } from './style'
import { localizedStrings } from 'constants/localizedStrings';

function EmptyStateContainer({ context, ...options }) {
    return (
        <EmptyStateWrapper {...options.containerOptions}>
            <Text
                fontSize={"16px"}
                color={"#767676"}
                fontWeigth="500"
                lineHeight="21px"
                {...options.subtitleOptions}
            >
                {localizedStrings.noVehicleSolicitations + " " + localizedStrings.solicitationStatus[context]?.capslockSingular}
            </Text>
        </EmptyStateWrapper >
    );
}
export default memo(EmptyStateContainer)