import React, { useState } from 'react'
import { localizedStrings } from "constants/localizedStrings";
import { useSelector } from "react-redux";
import { CardInput, Button, Text, Link, } from "components";
import {
    LoginActionButtons
} from "./style.js";
import { SYSTEM_LOGIN_FORGOT_PATH } from 'constants/paths.js';
export default function DriverLoginForm({
    onSubmit,
    errors,
    inputsConfig,
}) {
    const {
        loginLoading,
    } = useSelector(state => state.auth);

    const [passwordVisibility, setPasswordVisibility,] = useState(false);

    return (
        <form>
            <Text
                color="#262D60"
                fontSize="24px"
                lineHeight="34px"
                white-space="normal"
                font-weight="500"
                font-style="normal"
                padding="20px 0 0 0"
            >
                {localizedStrings.welcomeToVehicleSolicitation}
            </Text>
            <Text
                color="#868E96"
                fontSize="14px"
                lineHeight="22px"
                white-space="normal"
                font-style="normal"
                padding="20px 0"
            >
                {localizedStrings.vehicleSolicitationDescription}
            </Text>
            <CardInput
                padding="5px 0"
                onChange={inputsConfig.onChange}
                register={inputsConfig.register}
                inputs={[
                    {
                        label: localizedStrings.typeYourEmail,
                        defaultValue: inputsConfig.getValues()?.email,
                        name: "email",
                        type: "email",
                        required: true,
                        error: errors.email.error,
                        errorTitle: errors.email.error ? errors.email.message : false,
                        placeholder: localizedStrings.typeEmailExample,
                    },
                ]}
            />
            <CardInput
                padding="5px 0"
                onChange={inputsConfig.onChange}
                register={inputsConfig.register}
                inputs={[
                    {
                        label: localizedStrings.typePassword,
                        defaultValue: inputsConfig.getValues()?.password,
                        name: "password",
                        type: passwordVisibility ? "text" : "password",
                        error:
                            errors.password.error
                                ? true
                                : errors.emailPasswordWrong.error
                                    ? true
                                    : false,
                        errorTitle:
                            errors.password.error
                                ? errors.password.message
                                : errors.emailPasswordWrong.error
                                    ? errors.emailPasswordWrong.message
                                    : null,
                        required: true,
                        noMask: true,
                        iconOptions: {
                            icon: "eye",
                            onClick: () => setPasswordVisibility(visibility => !visibility),
                        },
                        placeholder: localizedStrings.typePassword,
                    },
                ]}
            />
            <LoginActionButtons>
                <Link
                    color="#1D1B84"
                    fontSize="13px"
                    fontWeight="bold"
                    href={SYSTEM_LOGIN_FORGOT_PATH}
                >
                    {localizedStrings.forgotMyPassoword}
                </Link>
                <Button
                    backgroundColor={loginLoading ? false : "#192379"}
                    color="#fff"
                    width="123px"
                    height="36px"
                    minWidth="41px"
                    padding="0"
                    onClick={onSubmit}
                    title={localizedStrings.access}
                    textConfig={{
                        fontWeight: '500',
                        fontSize: "13px",
                        color: "#fff"
                    }}
                    disabled={loginLoading}
                />
            </LoginActionButtons>
        </form>
    )
}

