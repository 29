
import styled from 'styled-components';

const attributesByVisibility = ({
    isCurrentStep,
    withDisplayNone = false,
    withZIndex = true,
}) => {
    if (isCurrentStep) {
        return {
            opacity: "1",
            zIndex: withZIndex ? "999" : "",
        }
    }
    return {
        opacity: "0",
        position: "absolute",
        zIndex: "0",
        width: "0",
        display: withDisplayNone ? "none" : "block"
    }

}
export const StepStyle = styled.div(props => ({
    "& ": {
        ...attributesByVisibility({
            isCurrentStep: props.isCurrentStep,
        })
    },
    "& *": {
        ...attributesByVisibility({
            isCurrentStep: props.isCurrentStep,
            withZIndex: false,
        })
    },
    "& path": {
        ...attributesByVisibility({
            isCurrentStep: props.isCurrentStep,
            withDisplayNone: true,
        }, true)
    },
    top: "0",
    left: "0",
    ...props,
}));

