import React, { memo, forwardRef } from "react";
import IcoMoon from "react-icomoon";
import iconSet from "../../assets/icons.json";
import Tooltip from '@material-ui/core/Tooltip';
import {
  withStyles
} from "@material-ui/core/styles";
import { StylableDiv } from './style'
const TextOnlyTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "#fff",
    fontSize: "14px",
    border: "1px solid #00000070"
  }
})(Tooltip);
const Icon = forwardRef((props, ref) => (
  <StylableDiv ref={ref} {...props.divProps}>
    <IcoMoon xmlns="http://www.w3.org/2000/svg" ref={ref} iconSet={iconSet} {...props} />
  </StylableDiv>
))
export default memo(({ tooltipText = "", ...props }) => {
  return (
    tooltipText
      ? <TextOnlyTooltip title={tooltipText} arrow interactive {...props.tooltipOptions}>
        <Icon {...props} />
      </TextOnlyTooltip>
      : <Icon {...props} />
  );
});

