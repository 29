import styled from 'styled-components';
import carsBackground from "assets/cars-background-2.png";

export const BackgroundWithIcon = styled.div(props => ({
    height: "100px",
    backgroundImage: `url(${carsBackground})`,
    backgroundPosition: "top",
    backgroundSize: "cover",
    position: "absolute",
    bottom: "0",
    width: "100%",
    left: "0",
    ["@media" + props.theme.device.laptopM]: {
        "&": {
            display: "none",
        },
    },
    ...props,
}))
