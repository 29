import styled from 'styled-components';

const TextSummary = styled.div(props => ({
    marginBottom: "10px",
    margin: "0",
    borderLeft: "1px solid #CFDAE5",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    minWidth: "130px",
    ...props,
}));
const IconSummary = styled.div(props => ({
    borderRadius: "10px 0px 0px 10px",
    margin: "0",
    padding: "10px 5px",
    ...props,
}));


export {
    TextSummary,
    IconSummary,
}