import React from 'react'
import { localizedStrings } from 'constants/localizedStrings'
import { DateInput, Col, CardInput } from 'components'
import { Row } from 'reactstrap'
import {
    StepStyle
} from '../style'
export default function StepTwo({
    solicitation,
    onChange,
    setSolicitation,
    currentStep,
}) {

    const changeAttributeNames = (item, values) => {
        const dateTypes = {
            startDate: "start_date",
            endDate: "end_date",
        };
        const fieldName = dateTypes[item];

        return {
            [fieldName]: values[item]
        }
    };

    const mergeAtrributes = (total, current) => ({
        ...total,
        ...current
    });

    return (
        <StepStyle isCurrentStep={+currentStep === 1}>
            <DateInput
                onChange={(e, values) => {

                    const period = Object.keys(values)
                        .map(item => changeAttributeNames(item, values))
                        .reduce(mergeAtrributes);

                    setSolicitation({
                        ...solicitation,
                        ...period
                    });

                }}
                divStyle={{
                    position: "relative"
                }}
                type="dateRangePicker"
                required={true}
                label={localizedStrings.selectDate}
                name={"date"}
                style={{
                    marginBottom: "10px"
                }}
            />
            <Row>
                <Col md="12" xl="6">
                    <CardInput
                        onChange={onChange}
                        inputs={[
                            {
                                label: localizedStrings.startHour,
                                defaultValue: solicitation?.start_time,
                                name: "start_time",
                                required: true,
                                type: "time",
                                placeholder: localizedStrings.typeStartHour,
                            }
                        ]}
                    />
                </Col>
                <Col md="12" xl="6">
                    <CardInput
                        onChange={onChange}
                        inputs={[
                            {
                                label: localizedStrings.endHour,
                                defaultValue: solicitation?.end_time,
                                name: "end_time",
                                required: true,
                                type: "time",
                                placeholder: localizedStrings.typeEndHour,
                            }
                        ]}
                    />
                </Col>
            </Row>
        </StepStyle>
    )
}
