import React, { useMemo, useRef } from 'react'
import {
    StepHeader,
    StepOne,
    StepTwo,
    StepThree,
    ScreenSuccessSolicitation,
} from 'components';
import { useSelector } from 'react-redux';

export default function StepsContent({
    solicitation,
    setSolicitation,
    onSave,
}) {
    const inputRef = useRef(null);

    const {
        steps
    } = useSelector(state => state.solicitations);

    const currentStep = useMemo(
        () => {
            return steps
                .map((step, index) => ({
                    ...step,
                    index,
                }))
                .filter(step => step.active)
                .shift();
        },
        [steps]
    );

    const onChange = (
        field,
        value
    ) => {
        inputRef?.current?.[field] && clearTimeout(inputRef?.current?.[field]);

        inputRef.current = {
            ...inputRef.current,
            [field]: setTimeout(() => {
                setSolicitation({
                    ...solicitation,
                    [field]: value
                })
            }, 250)
        }
    }

    const hasToShowNextButton = () => {
        const steps = {
            "0": () => !!solicitation?.descr,
            "1": () => !!solicitation?.start_date && !!solicitation?.end_date && !!solicitation?.start_time && !!solicitation?.end_time,
            "2": () => true,
            "3": () => true,
        };
        return steps[currentStep?.index]?.();
    }

    return (
        <StepHeader
            currentStep={currentStep}
            showNextButton={hasToShowNextButton()}
            onSave={onSave}
        >
            <StepOne
                solicitation={solicitation}
                onChange={onChange}
                currentStep={currentStep?.index}
            />
            <StepTwo
                solicitation={solicitation}
                onChange={onChange}
                setSolicitation={setSolicitation}
                currentStep={currentStep?.index}
            />
            <StepThree
                solicitation={solicitation}
                onChange={onChange}
                currentStep={currentStep?.index}
            />
            <ScreenSuccessSolicitation
                currentStep={currentStep?.index}
            />
        </StepHeader>
    )
}
