import { useSelector } from "react-redux";

const statesWithFormLoading = [
    "solicitations",
]

export default () => {
    let loading = false;

    const formIsLoading = statesWithFormLoading
        .map(statesNames => {
            const {
                createLoading,
                editLoading,
            } = useSelector(state => state[statesNames]);
            return loading || editLoading || createLoading;
        })
        .some(isLoading => isLoading);

    return formIsLoading;
};
