import {
  ORGANIZATION_LOAD_SUCCESS,
  ORGANIZATION_LOAD_FAIL,
  ORGANIZATION_LOAD_REQUEST,
  ORGANIZATION_UPDATE_SUCCESS,
  ORGANIZATION_UPDATE_FAIL,
  ORGANIZATION_UPDATE_REQUEST,
} from "./reducer";
import { api } from "services/api";
import qs from "qs";

export function fetchLoadSuccess({ organization }) {
  return {
    type: ORGANIZATION_LOAD_SUCCESS,
    payload: {
      organization,
    },
  };
}

export function fetchLoadFail(messageFail = "") {
  return {
    type: ORGANIZATION_LOAD_FAIL,
    payload: {
      messageFail,
    },
  };
}

export function fetchLoadRequest() {
  return { type: ORGANIZATION_LOAD_REQUEST };
}

export function fetchUpdateSuccess({ organization }) {
  return {
    type: ORGANIZATION_UPDATE_SUCCESS,
    payload: {
      organization,
    },
  };
}

export function fetchUpdateFail(messageFail = "") {
  return {
    type: ORGANIZATION_UPDATE_FAIL,
    payload: {
      messageFail,
    },
  };
}

export function fetchUpdateRequest() {
  return { type: ORGANIZATION_UPDATE_REQUEST };
}

export const loadOrganization = (data) => async (dispatch) => {
  try {
    const URL_ORG = "/organization/v1/";

    dispatch(fetchLoadRequest());

    const {
      data: { organization }
    } = await api.get(URL_ORG + data.organization_id);

    dispatch(fetchLoadSuccess({ organization, }));

  } catch (error) {
    dispatch(fetchLoadFail());
    //   dispatch(driverCreateFail());
    console.log("err", error);
  }
};

export const updateOrganization = (data) => async (dispatch) => {
  try {
    const URL_ORG = "/organization/v1/" + data.organization_id;

    delete data.data.organization.isSameAddress;
    delete data.data.organization.address1;
    delete data.data.organization.address2;

    dispatch(fetchUpdateRequest());
    const {
      organization
    } = await api.put(URL_ORG, qs.stringify({ organization: data.data.organization }))

    dispatch(fetchUpdateSuccess({ organization }));

  } catch (error) {
    dispatch(fetchUpdateFail("Aconteceu algo de errado, tente novamente"));
  }
};
